<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2023-02-16 23:33:27
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2024-12-03 10:39:21
-->
<template>
  <div class="home">
    <div class="desc">
      <div class="login">
        <h2>{{ config.siteName }}</h2>
        <el-form
          ref="formRef"
          :model="form"
          label-position="top"
          :rules="rules"
          class="login-from"
        >
          <el-tabs v-model="form.loginType" @tab-change="tabChange">
            <el-tab-pane
              label="密码登录"
              :name="0"
              v-if="config.openAdminLoginByPassword === '1'"
            ></el-tab-pane>
            <el-tab-pane label="验证码登录" :name="1"  v-if="config.openAdminLoginBySmsCode === '1'"></el-tab-pane>
          </el-tabs>

          <el-form-item :label="hint.accountLabel" prop="accountOrPhone">
            <el-input
              v-model="form.accountOrPhone"
              :placeholder="hint.accountOrPhone"
              size="large"
              @input="onChange"
            ></el-input>
          </el-form-item>
          <el-form-item :label="hint.pwLabel" prop="pwdOrSmsCode">
            <el-input
              :show-password="form.loginType === 0"
              :placeholder="hint.pwdOrSmsCode"
              v-model="form.pwdOrSmsCode"
              size="large"
              @input="onChange"
              @keyup.enter="onSubmit"
            ></el-input>

            <el-button
              class="btn-sms"
              text
              v-if="form.loginType == 1"
              @click="onSms"
              :disabled="btnSms.disabled"
              >{{ btnSms.text }}</el-button
            >
          </el-form-item>
          <!-- <el-form-item label>
          <el-checkbox v-model="form.remember">一周内自动登录</el-checkbox>
        </el-form-item> -->

          <el-form-item>
            <el-button
              style="width: 100%; margin-top: 20px"
              type="primary"
              :loading="loading"
              @click="onSubmit"
              size="large"
              round
              >立即登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="icp">琼ICP备2020004009号-2</div>
  </div>
</template>

<script>
import md5 from "js-md5";

export default {
  components: {},
  data() {
    return {
      loading: false,
      disabled: true,
      config:{},
      form: {
        loginType: 0,
        accountOrPhone: "",
        pwdOrSmsCode: "",
        remember: false,
      },
      rules: {
        accountOrPhone: [
          { required: true, message: "请输入", trigger: "input" },
        ],
        pwdOrSmsCode: [{ required: true, message: "请输入", trigger: "input" }],
      },
      hint: {
        accountOrPhone: "请输入手机号码",
        pwdOrSmsCode: "请输入登录密码",
        accountLabel: "手机",
        pwLabel: "密码",
      },
      btnSms: {
        text: "获取验证码",
        disabled: false,
      },
    };
  },
  created() {
    var mobile = localStorage.getItem("loginAccount");
    if (mobile) {
      this.form.accountOrPhone = mobile;
    }

    var _this = this;

    this.$http.get("v1/common/config").then((res) => {
      if (res.code === 0) {
        this.config = res.data;
        window
          .vaptcha({
            vid: res.data.vaptchaVid,
            mode: "invisible",
            scene: 0,
            area: 'auto',
          })
          .then(function (VAPTCHAObj) {
            _this.vaptcha = VAPTCHAObj;
            // 渲染验证组件
            VAPTCHAObj.render();

            // 验证成功进行后续操作
            VAPTCHAObj.listen("pass", function () {
              _this.btnSms.disabled = true;
              var serverToken = VAPTCHAObj.getServerToken();
              _this.$http
                .post("v1/common/sms/captcha", {
                  mobile: _this.form.accountOrPhone,
                  type: 1,
                  token: serverToken.token,
                  server: serverToken.server,
                  scene: 0,
                })
                .then((res) => {
                  if (res.code == 0) {
                    _this.$message({
                      type: "success",
                      message: res.msg,
                    });
                    //发送成功，倒计时
                    _this.btnSms.disabled = true;
                    var sec = 60;
                    var t = setInterval(() => {
                      sec--;
                      if (sec <= 0) {
                        clearInterval(t);
                        _this.btnSms.disabled = false;
                        _this.btnSms.text = "获取验证码";
                        VAPTCHAObj.reset();
                      } else {
                        _this.btnSms.text = `${sec}s`;
                      }
                    }, 1000);
                  } else {
                    VAPTCHAObj.reset();
                  }
                });
            });

            VAPTCHAObj.listen("close", function () {
              console.log("验证弹窗关闭触发");
              // 验证弹窗关闭触发
              VAPTCHAObj.reset();
            });
          });
      }
    });
  },
  methods: {
    /**
     * 登陆类型更改
     */
    tabChange(val) {
      this.form.pwdOrSmsCode = "";
      if (val == 1) {
        if (!this.$utils.is_phone(this.form.accountOrPhone)) {
          this.form.accountOrPhone = "";
        }

        this.hint.accountOrPhone = "请输入手机号码";
        this.hint.pwdOrSmsCode = "请输入验证码";
        this.hint.accountLabel = "手机";
        this.hint.pwLabel = "验证码";
      } else {
        this.hint.accountOrPhone = "账号名/手机号";
        this.hint.pwdOrSmsCode = "请输入登录密码";
        this.hint.accountLabel = "账号";
        this.hint.pwLabel = "密码";
      }
    },

    /**
     * 输入框更改
     */
    onChange: function () {
      this.disabled = !(this.form.accountOrPhone && this.form.pwdOrSmsCode);
    },

    /**
     * 登录
     */
    onSubmit: async function () {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          var postModel = Object.assign({}, this.form);

          if (this.form.loginType == 0) {
            var salt = await this.getSalt();
            postModel.pwdOrSmsCode = md5(this.form.pwdOrSmsCode + salt);
          }
          //记录登录账号
          localStorage.setItem("loginAccount", this.form.accountOrPhone);
          this.$http
            .post("admin/v1/login", postModel)
            .then((res) => {
              if (res.code === 0) {
                //存储token
                sessionStorage.setItem(this.$cfg.TOKEN, res.data.token);
                this.$router.push({ name: "dashboard" });
              }
            })
            .finally(() => (this.loading = false));
        }
      });
    },

    /**
     * 获取密码盐
     */
    getSalt() {
      return new Promise((resolve, reject) => {
        this.$http
          .get("admin/v1/login/get_salt?account=" + this.form.accountOrPhone)
          .then((res) => {
            if (res.code == 0) {
              return resolve(res.data.salt);
            } else {
              return reject(res.msg);
            }
          });
      });
    },

    /**
     * 获取短信验证码
     */
    onSms() {
      if (this.form.accountOrPhone) {
        if (this.$utils.is_phone(this.form.accountOrPhone)) {
          this.vaptcha.validate();
          return;
        }
      }
      this.$message({ type: "error", message: "请输入手机号码" });
    },
  },
};
</script>

<style scoped>
.home {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f5f5f5;
}

.home .desc {
  flex-grow: 1;
  height: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home .desc .login {
  width: 420px;
  background: #fff;
  border-radius: 10px;
  padding: 40px;
  box-shadow: var(--el-box-shadow-light);
}

.home .icp { 
  color: #909399;
  padding: 20px 0;
  display: flex;
  align-items: center;
}

.home .login h2{
  margin-top: 0;
}

.btn-sms {
  position: absolute;
  right: 10px;
}
</style>
